import { useEffect, useRef } from 'react';

function useInitialRender(callback: () => void) {
  // const [isInitialRender, setIsInitialRender] = useState(true);
  const isFirstRender = useRef(true);


  useEffect(() => {
    if (isFirstRender.current) {
      callback();
      isFirstRender.current = false;
    }
  }, [callback]);
}

export default useInitialRender;
