import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { SocketIOProvider } from "./contexts/SocketIOContext";
import Hotjar from "./Hotjar";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import "./styles/index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || ''

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
    <SocketIOProvider>
      <Provider store={store}>
        <Router>
        <HelmetProvider>
          <Hotjar />
          <App />
          </HelmetProvider>
        </Router>
      </Provider>
      </SocketIOProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
