import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { alertSchema, dropDownOption, teamSchema, userProfileSchema } from '../../interfaces';

// THIS HOLDS AUTH INFO, DASHBOARD INFO, LOADER AND ALERT COMPONENT INFO
export interface MainStoreState {
  loadingState: boolean;
  value: number;
  userProfile: userProfileSchema | null,
  alert: alertSchema
  showAlert: boolean,
  showNotifications: boolean
  jwtToken: string | null,
  sample: any
  selectedTeam: teamSchema | undefined
  userTeams: teamSchema[]
  jobRoles: dropDownOption[]
  skills: dropDownOption[]
}

const initialState: MainStoreState = {
  value: 0,
  loadingState: false,
  userProfile: null,
  showNotifications: false,
  alert: {
    type: 'info',
    messageBody: 'Please select',
    messageTitle: 'Please select the sample'
  },
  userTeams: [],
  selectedTeam: undefined,
  showAlert: false,
  sample: {},
  jwtToken: '',
  jobRoles: [],
  skills: []
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateUserProfile: (state, action) => {
      if (state.userProfile === null) {
        state.userProfile = action.payload;
      } else {
        state.userProfile = { ...state.userProfile, ...action.payload}
      }
    },
    updateUserTeams: (state, action: PayloadAction<teamSchema[]>) => {
      state.userTeams = action.payload;
      localStorage.setItem("userTeams", JSON.stringify(action.payload));
    },
    hideAlert: (state) => {
      state.showAlert = false;
    },
    updateShowNotifications: (state, action: PayloadAction<boolean>) => {
      state.showNotifications = action.payload;
    },
    toggleLoader: (state, action: PayloadAction<boolean>) => {
      state.loadingState = action.payload
    },
    setAlert: (state, action: PayloadAction<MainStoreState['alert']>) => {
      state.alert = action.payload;
      state.showAlert = true;
    },
    setSelectedTeam: (state, action: PayloadAction<teamSchema>) => {
      state.selectedTeam = action.payload;
    },
    updateTokenStatus: (state, action) => {
      state.jwtToken = action.payload;
      if (action.payload) {
        localStorage.setItem('talenvoJwToken', action.payload);
      } else {
        localStorage.removeItem('talenvoJwToken')
        localStorage.removeItem('skills')
        localStorage.removeItem('talenvoUserProfile')
      }
    },
    updateJobRoles: (state, action) => {
      state.jobRoles = action.payload
    },
    updateSkills: (state, action) => {
      localStorage.setItem('skills', JSON.stringify(action.payload))
      state.skills = action.payload
    },
  },
});

export const { updateJobRoles, setSelectedTeam, updateUserTeams, updateSkills, updateShowNotifications, toggleLoader, hideAlert, updateTokenStatus, setAlert, updateUserProfile } = mainSlice.actions;


export const selectCount = (state: RootState) => (state.main as any).value;
export const showNotifications = (state: RootState) => (state.main as any).showNotifications as boolean;
export const showingAlert = (state: RootState) => (state.main as any).showAlert as boolean;
export const alertData = (state: RootState) => (state.main as any).alert;
export const userTeams = (state: RootState) => (state.main as any).userTeams as teamSchema[];
export const userToken = (state: RootState) => (state.main as any).jwtToken;
export const loadingState = (state: RootState) => (state.main as any).loadingState as boolean;
export const userProfile = (state: RootState) => (state.main as any).userProfile as userProfileSchema;
export const availableSkills = (state: RootState) => (state.main as any).skills;
export const availableJobRoles = (state: RootState) => (state.main as any).jobRoles;
export const showSelectedTeam = (state: RootState) => (state.main as any).selectedTeam as teamSchema;

export default mainSlice.reducer;
