import React, { useEffect } from 'react';

const Hotjar: React.FC = () => {
    useEffect(() => {
        const hotjarId = process.env.REACT_APP_HOTJAR_ID;

        if (!hotjarId) {
            console.error('Hotjar ID is not defined in environment variables');
            return;
        }

        const script = document.createElement('script');
        script.innerHTML = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${hotjarId},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;

        try {
            document.head.appendChild(script);
        } catch (error) {
            console.error('Error appending Hotjar script:', error);
        }

        return () => {
            try {
                document.head.removeChild(script);
            } catch (error) {
                console.error('Error removing Hotjar script:', error);
            }
        };
    }, []);

    return null;
};

export default Hotjar;