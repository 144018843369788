import axios from "axios";
import * as htmlToImage from 'html-to-image';
import {networkInstance} from "./network";
import { GetAssessmentSchema, challengeSchema, deliverableMappingSchema, dropDownOption } from "../interfaces";

const mixin = {
  hasDateArrived(isoDateString:string) {
    const targetDate = new Date(isoDateString);
    const currentDate = new Date();
    
    // Set both dates to start of day for accurate comparison
    targetDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    
    return currentDate >= targetDate;
  },
  async fetchJobRoles() {
    try {
      const { status, data } = await networkInstance.get("/job-titles");
      if (status === 200) {
        return data;
      }
    } catch (err: any) {
      const { data } = err?.response || {};;
      return {
        type: "warning",
        messageTitle: "Sorry! 🫠",
        messageBody: data?.message || "Something went wrong",
      };
    }
  },
  oneDayPassed (startDate: string): boolean {
    const currentDate = new Date();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 1 day in milliseconds
    const timeDifference =
      currentDate.getTime() - new Date(startDate).getTime();

    return timeDifference >= oneDayInMilliseconds;
  },
  getRandomIntInclusive (min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  capitalize (word:string) {
    return word.charAt(0).toUpperCase() + word.slice(1)
  },
  formatAmount: (x:number) => {
    if (x) {
      return Number(x).toLocaleString("en-US");
    } else {
      return 0;
    }
  },
  async fetchSkills() {
    try {
      const { status, data } = await networkInstance.get("/skills");
      if (status === 200) {
        return data;
      }
    } catch (err: any) {
      const { data } = err?.response || {};;
      return {
        type: "warning",
        messageTitle: "Sorry! 🫠",
        messageBody: data?.message || "Something went wrong",
      };
    }
  },
  removeDuplicatesById (arr: dropDownOption[]):dropDownOption[] {
    const map = new Map();
    return arr.filter(item => !map.has(item.id) && map.set(item.id, true));
  },
  computedSkillName (skillId:string, skillArray: dropDownOption[]) {
    if (!skillId || skillArray.length < 1) {
      return '';
    }
    const skill = skillArray.find((skill) => skill.id === skillId);
    return skill?.name || '';
  },
  returnSelectedAssessment(assessments:GetAssessmentSchema[], allSkills:dropDownOption[], selectedSkillId:string): {
    skill: string,
    assessmentObject: GetAssessmentSchema | undefined
  } {
    const selectedSkill = allSkills.find((item: dropDownOption) => item.id === selectedSkillId);
    let primarySkill:string = ''
    let assessmentObject = undefined
    if (selectedSkill) {
      primarySkill = selectedSkill['name'];
    }
    const allSubmittedRecords = assessments.filter(assessment => assessment.scenario_type === 'real' && assessment.skill.name.toLocaleLowerCase() === selectedSkill?.name.toLocaleLowerCase() && assessment.submittedDate);
    if (allSubmittedRecords.length > 0) {
      const sortedArray = allSubmittedRecords.sort((a, b) => new Date(b.submittedDate as unknown as Date).getTime() - new Date(a.submittedDate as unknown as Date).getTime());
      assessmentObject = sortedArray[0];
    } else {
      const allRecords = assessments.filter(assessment => assessment.skill.name.toLocaleLowerCase() === selectedSkill?.name.toLocaleLowerCase() && !assessment.submittedDate);
      if (allRecords.length > 0) {
        assessmentObject = allRecords[0];
      } 
  }
  return {
    skill: primarySkill,
    assessmentObject
  }
},
  filterLatestAssessments(assessments: GetAssessmentSchema[]): GetAssessmentSchema[] {
    const filteredAssessments = assessments.reduce((acc, assessment) => {
      const existingIndex = acc.findIndex(a => a.skill.id === assessment.skill.id);
  
      if (existingIndex === -1) {
        // No existing assessment for this skill, add it directly
        acc.push(assessment);
      } else {
        // Existing assessment found, compare created dates
        const existingAssessment = acc[existingIndex];
        if (new Date(assessment.createdDate) > new Date(existingAssessment.createdDate)) {
          // Update with the newer assessment
          acc[existingIndex] = assessment;
        }
      }
  
      return acc;
    }, [] as GetAssessmentSchema[]);
  
    return filteredAssessments;
  },
  getHost() {
    const { protocol, hostname, port } = window.location;
    if (port) {
      return `${protocol}//${hostname}:${port}`;
    }
    return `${protocol}//${hostname}`;
  },
  async uploadFileToCloudinary(userFile: File | null, fileType: 'image' | 'raw'='image'): Promise<string | null> {
    if (!userFile) {
      return null;
    }
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || '';
    const unsignedUploadPreset = process.env.REACT_APP_CLOUDINARY_UNSIGNED_UPLOAD_PRESET || '';
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/${fileType}/upload`;
    const formdata = new FormData();
    formdata.append('file', userFile);
    formdata.append('upload_preset', unsignedUploadPreset);
    try {
      const { data } = await axios.post(url, formdata)
      return data?.secure_url
    } catch (e) {
      return null;
    }
  
  },
  getDeliverableMappings: (challenge: challengeSchema | undefined) => {
    const mapping: deliverableMappingSchema[] = [];
  
    if (challenge && challenge?.deliverables) {
      challenge.deliverables.forEach(deliverable => {
        const existingSkillIndex = mapping.findIndex(item => item.id === deliverable.skill.id);
  
        if (existingSkillIndex !== -1) {
          const existingSkill = mapping[existingSkillIndex];
          const existingDeliverableIndex = existingSkill.content.findIndex(item => item.title.toLowerCase() === deliverable.title.toLowerCase());
  
          if (existingDeliverableIndex === -1) {
            existingSkill.content.push({ title: deliverable.title, content: deliverable.description });
          }
        } else {
          mapping.push({
            id: deliverable.skill.id,
            title: deliverable.skill.name,
            content: [{ title: deliverable.title, content: deliverable.description }]
          });
        }
      });
    }
    return mapping;
  },
  createShare(htmlElement:string) {
    const node = document.getElementById(htmlElement);
    if (node) 
    htmlToImage.toPng(node)
    .then(function (dataUrl:any) {
      var img = new Image();
      img.src = dataUrl;
      document.body.appendChild(img);
    })
    .catch(function (error:any) {
      console.error('oops, something went wrong!', error);
    });
  },
  formatDate (dateString: string) {
    // from 2015-02-02 to 2024-02-02T09:37:16.164Z

    // Create a Date object from the dateString
    const date = new Date(dateString);
  
    // Get the parts of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
  
    // Create the formatted date string
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  
    return formattedDate;
  },
  extractDate (dateTimeString: string | Date) {
    // from 2024-02-02T09:37:16.164Z to 2015-02-02
    // Create a Date object from the dateTimeString
    const date = new Date(dateTimeString);
  
    // Get the parts of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
  
    // Create the formatted date string
    const formattedDate = `${year}-${month}-${day}`;
  
    return formattedDate;
  },
  simplifyDate(dateTimeString: string | Date | null, includeDay: boolean = false): string {
    // Converts from "2024-02-02T09:37:16.164Z" to "Feb, 2024" or "Feb 2, 2024" if includeDay is true
    if (dateTimeString) {
      const date = new Date(dateTimeString);
  
      interface DateFormatOptions {
        month: 'short';
        year: 'numeric';
        day?: 'numeric'; // Optional day field
      }
  
      const options: DateFormatOptions = { month: 'short', year: 'numeric' };
  
      if (includeDay) {
        options.day = 'numeric'; // Include the day if specified
      }
  
      return date.toLocaleDateString('en-US', options);
    } else {
      return '';
    }
  },
  truncateText(arg: string, limit: number = 8): string {
    if (!arg) return '';
    return arg.length > limit ? `${arg.slice(0, limit)}...` : arg;
  },  
  initials(firstName?:string, lastName?:string): string {
    if (firstName && lastName) {
      return `${firstName.slice(0, 1) || ''}${lastName.slice(0, 1) || ''}`
    }
    return '';
  },
  setRandomColorArray (initials?: string) {
    const colors = ["bg-green-600", "bg-red-600", "bg-blue-600", "bg-green-300",  "bg-red-300", "bg-sky-500", "bg-sky-800", "bg-orange-400", "bg-blue-300", "bg-pink-400", "bg-purple-600", "bg-red-800"	];
    if (!initials) {  
      return colors[0];
    } else {
      let hash = 0;
  
      // Create a hash from the string
      for (let i = 0; i < initials.length; i++) {
        hash = (hash << 5) - hash + initials.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
      }
    
      // Map the hash to a number between 1 and 12
      const randomNumber = Math.abs(hash) % 11 + 1;
    const randomColor = colors[randomNumber];

      return randomColor;
    }

  },
  generateDynamicTeamNameIdWithDateTime(prefix: string = 'id', length: number = 9): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    const dateTimeString = new Date().toISOString().replace(/[-:]/g, '').replace('T', '').replace(/\..+/, '');
    let id = `${prefix.slice(0,4)}_${dateTimeString.split('').reverse().join('').slice(0,3)}`;
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      id += characters.charAt(randomIndex);
    }
  
    return id;
  },
  generateDynamicIdWithDateTime(prefix: string = 'id', length: number = 9): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    const dateTimeString = new Date().toISOString().replace(/[-:]/g, '').replace('T', '').replace(/\..+/, '');
    let id = `${prefix}_${dateTimeString}`;
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      id += characters.charAt(randomIndex);
    }
  
    return id;
  },
  formatTime(date: Date): string {
    // Function to format time as "h:mm am/pm"
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (12 am)
    return `${hours}:${minutes}${ampm}`;
  },
  formatDateTime(date: Date | string): string {
    // Function to format date or time dynamically based on time period
    const now = new Date();
    const dateString = new Date(date);
    const timeDifference = now.getTime() - dateString.getTime(); // Time difference in milliseconds
    const hoursDifference = timeDifference / (1000 * 60 * 60); // Convert milliseconds to hours
  
    if (hoursDifference < 12) {
      return this.formatTime(dateString);
    } else {
      return this.extractDate(dateString);
    }
  },
  calculateDaysSince(dateTimeString: Date | string):number {
      const date = new Date(dateTimeString);
          // Get the current date and time
    const currentDate = new Date();
  
    // Calculate the time difference in milliseconds
    const timeDifferenceMs = currentDate.getTime() - date.getTime();
  
    // Convert milliseconds to days
    const daysPassed = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
  
    return daysPassed;
    

  }
};

export default mixin;
